<template>
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <div class="col-12 md:col-5">
            <div v-if="hash == null || b == null" class="card">
                <div class="mb-4 mt-4" style="text-align: center">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
            <div v-else class="card">
                <div class="mb-2 mt-4">
                    <b>找到編輯中的訂單，要繼續編輯該訂單嗎？</b>
                </div>
                <div class="mb-2 mt-4 lg:col-12 lg:mb-0">
                    <Button v-on:click="doEdit()" label="繼續編輯" class="p-button-purpply p-button mr-2" />
                </div>
                <div class="mb-2 mt-4 lg:col-12 lg:mb-0">
                    <Button v-on:click="doNew()" label="建立新訂單" class="p-button-purpply p-button mr-2" />
                </div>
                <div class="mb-4 mt-0">
                    * 若建立新訂單，原訂單內容將無法再送出
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
    data() {
        return {
            hash: null,
            b: null,
        };
    },
    mounted() {
        this.setHyperlink();
    },
    beforeUnmount() {},
    created() {},
    methods: {
        fetchAvailableShops() {},
        setHyperlink() {
            const route = useRoute();
            this.hash = route.query.hash;
            this.b = decodeURI(route.query.b);
        },
        doEdit() {
            this.$router.replace({
                path: "/register", // Entrance
				name: "register",
                query: {
                    hash: this.hash,
                    b: this.b,
                },
            });
        },
        doNew() {
            this.$router.replace({
                path: "/register", // Entrance
				name: "register",
                query: {
                    hash: this.hash,
                    b: this.b,
                },
                params: {
                    n: 'T',
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;
    & + label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);
        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }
        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after {
            left: 100%;
        }
    }
    &.toggle-right + label {
        margin-left: 0px;
        &:after {
            left: -100%;
        }
    }
    &:checked + label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;
        &:after {
            left: 0;
        }
    }
}
</style>
